/*--------------------------
    Project Name: Medcity
    Version: 1.0
    Author: 7oorof
    Relase Date: April 2021
---------------------------*/
/*---------------------------
      Table of Contents
    --------------------
    01- Pre Loading
    02- Mobile Menu
    03- Sticky Navbar
    04- Scroll Top Button
    05- Close Topbar
    06- Set Background-img to section 
    07- Add active class to accordions
    08- Contact Form validation
    09- Slick Carousel
    10- Popup Video
    11- Progress bars
    12- NiceSelect Plugin
    13- Range Slider
     
 ----------------------------*/

  jQuery(function () {

    "use strict";

    // Global variables
    var $win =  jQuery(window);

    /*==========  Pre Loading   ==========*/
    setTimeout(function () {
         jQuery(".preloader").remove();
    }, 2000);

    /*==========   Mobile Menu   ==========*/
    jQuery('.navbar-toggler').on('click', function () {
        jQuery('.navbar-collapse').addClass('menu-opened');
    })

    jQuery('.close-mobile-menu').on('click', function (e) {
        jQuery('.navbar-collapse').removeClass('menu-opened');
    });

    /*==========   Sticky Navbar   ==========*/
    $win.on('scroll', function () {
        if ($win.width() >= 992) {
            var $stickyNavbar = jQuery('.sticky-navbar'),
                $secondaryNavbar = jQuery('.secondary-nav');
            if ($win.scrollTop() > 150) {
                $stickyNavbar.addClass('is-sticky');
            } else {
                $stickyNavbar.removeClass('is-sticky');
            }
            if ($secondaryNavbar.length) {
                if ($win.scrollTop() > $secondaryNavbar.offset().top - 100) {
                    $secondaryNavbar.addClass('secondary-nav-sticky');
                } else {
                    $secondaryNavbar.removeClass('secondary-nav-sticky');
                }
            }
        }
    });
    // Scroll To Section when Clicking on The Link
    jQuery('.secondary-nav-internal-navigation .nav__link').on("click", function (e) {
        e.preventDefault();
        jQuery('html, body').animate({
            scrollTop: jQuery('#' + jQuery(this).data('scroll')).offset().top - 140
        }, 1000);
    });

    // Add  active class when The Scroll Reaching the Section
    jQuery(window).on("scroll", function () {
        jQuery('section').each(function () {
            if (jQuery(window).scrollTop() > $(this).offset().top - 141) {
                var sectionID = $(this).attr('id');
                jQuery('.secondary-nav-internal-navigation .nav__link').removeClass('active');
                jQuery('.secondary-nav-internal-navigation .nav__link[data-scroll="' + sectionID + '"]').addClass('active');
            }
        });
    });

    /*==========   Scroll Top Button   ==========*/
    var $scrollTopBtn = jQuery('#scrollTopBtn');
    // Show Scroll Top Button
    $win.on('scroll', function () {
        if (jQuery(this).scrollTop() > 700) {
            $scrollTopBtn.addClass('actived');
        } else {
            $scrollTopBtn.removeClass('actived');
        }
    });
    // Animate Body after Clicking on Scroll Top Button
    $scrollTopBtn.on('click', function () {
        jQuery('html, body').animate({
            scrollTop: 0
        }, 500);
    });

    /*==========   Close Topbar   ==========*/
    jQuery('.topbar__close').on("click", function (e) {
        e.preventDefault();
        jQuery(this).closest('.topbar').fadeOut()
    });

    /*==========   Set Background-img to section   ==========*/
    jQuery('.bg-img').each(function () {
        var imgSrc = jQuery(this).children('img').attr('src');
        jQuery(this).parent().css({
            'background-image': 'url(' + imgSrc + ')',
            'background-size': 'cover',
            'background-position': 'center',
        });
        jQuery(this).parent().addClass('bg-img');
        if (jQuery(this).hasClass('background-size-auto')) {
            jQuery(this).parent().addClass('background-size-auto');
        }
        jQuery(this).remove();
    });

    /*==========   Add active class to accordions   ==========*/
    jQuery('.accordion__header').on('click', function () {
        jQuery(this).parent('.accordion-item').toggleClass('opened');
        jQuery(this).parent('.accordion-item').siblings().removeClass('opened');
    })
    jQuery('.accordion__title').on('click', function (e) {
        e.preventDefault()
    });

    /*==========  Open and Close Popup   ==========*/
    // open Mini Popup
    function openMiniPopup(popupTriggerBtn, popup, cssClass) {
        jQuery(popupTriggerBtn).on('click', function (e) {
            e.preventDefault();
            jQuery(this).toggleClass(cssClass);
            jQuery(popup).toggleClass(cssClass);
        });
    }
    // open Popup
    function openPopup(popupTriggerBtn, popup, addedClass, removedClass) {
        jQuery(popupTriggerBtn).on('click', function (e) {
            e.preventDefault();
            jQuery(popup).toggleClass(addedClass, removedClass).removeClass(removedClass);
        });
    }
    // Close Popup
    function closePopup(closeBtn, popup, addedClass, removedClass) {
        jQuery(closeBtn).on('click', function () {
            jQuery(popup).removeClass(addedClass).addClass(removedClass);
        });
    }
    // close popup when clicking on an other place on the Document
    function closePopupFromOutside(popup, stopPropogationElement, popupTriggerBtn, removedClass, addedClass) {
        jQuery(document).on('mouseup', function (e) {
            if (!jQuery(stopPropogationElement).is(e.target) && !jQuery(popupTriggerBtn).is(e.target) && jQuery(stopPropogationElement).has(e.target).length === 0 && jQuery(popup).has(e.target).length === 0) {
                jQuery(popup).removeClass(removedClass).addClass(addedClass);
            }
        });
    }
    openMiniPopup('.miniPopup-emergency-trigger', '#miniPopup-emergency', 'active') // Open miniPopup-emergency
    openMiniPopup('#miniPopup-departments-trigger-icon', '#miniPopup-departments', 'active') // Open miniPopup-emergency

    openPopup('.action__btn-search', '.search-popup', 'active', 'inActive') // Open sidenav popup
    closePopup('.search-popup__close', '.search-popup', 'active', 'inActive') // Close sidenav popup
    openPopup('.action__btn-burgerMenu', '.burger-menu', 'active', 'inActive') // Open sidenav popup
    closePopup('.burger-menu__close', '.burger-menu', 'active', 'inActive') // Close sidenav popup
    openPopup('.action__btn-cart', '.cart-popup', 'active', 'inActive') // Open Search popup
    closePopupFromOutside('.burger-menu', '.burger-menu__content', '.action__btn-burgerMenu', 'active', 'inActive');  // close popup when clicking on an other place on the Document

    openPopup('.open-login-popup', '#login-popup', 'active', 'inActive') // Open sidenav popup
    closePopupFromOutside('#login-popup', '.login-popup-wrapper', '.open-login-popup', 'active', 'inActive');  // close popup when clicking on an other place on the Document

    openPopup('.open-register-popup', '#register-popup', 'active', 'inActive') // Open sidenav popup
    closePopupFromOutside('#register-popup', '.login-popup-wrapper', '.open-register-popup', 'active', 'inActive');  // close popup when clicking on an other place on the Document

    // Close topbar
    jQuery('#close-topbar').on('click', function () {
        jQuery('#header-topbar').fadeOut();
    });

    /*==========   Increase and Decrease Input Value   ==========*/
    // Increase Value
    jQuery('.increase-qty').on('click', function () {
        var $qty = $jQuery(this).parent().find('.qty-input');
        var currentVal = parseInt($qty.val());
        if (!isNaN(currentVal)) {
            $qty.val(currentVal + 1);
        }
    });
    // Decrease Value
    jQuery('.decrease-qty').on('click', function () {
        var $qty = $(this).parent().find('.qty-input');
        var currentVal = parseInt($qty.val());
        if (!isNaN(currentVal) && currentVal > 1) {
            $qty.val(currentVal - 1);
        }
    });

    /*==========   Progress bars  ==========*/
    if (jQuery(".animated-Progressbars").length > 0) {
        jQuery(window).on('scroll', function () {
            var skillsOffset = jQuery(".animated-Progressbars").offset().top - 160,
                skillsHight = jQuery(this).outerHeight(),
                winScrollTop = jQuery(window).scrollTop();
            if (winScrollTop > skillsOffset - 1 && winScrollTop < skillsOffset + skillsHight - 1) {
                jQuery('.progress-bar').each(function () {
                    jQuery(this).width($(this).attr('aria-valuenow') + '%');
                });
                jQuery('.progress__percentage').each(function () {
                    jQuery(this).text($(this).parent('.progress-bar').attr('aria-valuenow') + '%')
                });
            }
        });
    }

    /*==========  Contact Form validation  ==========*/
    // var contactForm = jQuery("#contactForm"),
    //     contactResult = jQuery('.contact-result');
    // contactForm.validate({
    //     debug: false,
    //     submitHandler: function (contactForm) {
    //         jQuery(contactResult, contactForm).html('Please Wait...');
    //         $.ajax({
    //             type: "POST",
    //             url: "assets/php/contact.php",
    //             data: $(contactForm).serialize(),
    //             timeout: 20000,
    //             success: function (msg) {
    //                 $(contactResult, contactForm).html('<div class="alert alert-success" role="alert"><strong>Thank you. We will contact you shortly.</strong></div>').delay(3000).fadeOut(2000);
    //             },
    //             error: $('.thanks').show()
    //         });
    //         return false;
    //     }
    // });

    /*==========   Slick Carousel ==========*/
    jQuery('.slick-carousel').slick();

    jQuery('.slider-with-navs').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: false,
        dots: true,
        asNavFor: '.slider-nav'
    });

    jQuery('.slider-nav').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: '.slider-with-navs',
        dots: false,
        arrows: false,
        centerMode: false,
        focusOnSelect: true,
        infinite: false
    });

    /*==========  Popup Video  ==========*/
    jQuery('.popup-video').magnificPopup({
        mainClass: 'mfp-fade',
        removalDelay: 0,
        preloader: false,
        fixedContentPos: false,
        type: 'iframe',
        iframe: {
            markup: '<div class="mfp-iframe-scaler">' +
                '<div class="mfp-close"></div>' +
                '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
                '</div>',
            patterns: {
                youtube: {
                    index: 'youtube.com/',
                    id: 'v=',
                    src: '//www.youtube.com/embed/%id%?autoplay=1'
                }
            },
            srcAction: 'iframe_src',
        }
    });
    jQuery('.popup-gallery-item').magnificPopup({
        type: 'image',
        tLoading: 'Loading image #%curr%...',
        mainClass: 'mfp-img-mobile',
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0, 1]
        },
        image: {
            tError: '<a href="%url%">The image #%curr%</a> could not be loaded.'
        }
    });

    /*==========  NiceSelect Plugin  ==========*/
    jQuery('select').niceSelect();

    /*==========   Range Slider  ==========*/
    var $rangeSlider = jQuery("#rangeSlider"),
        $rangeSliderResult = jQuery("#rangeSliderResult");
    $rangeSlider.slider({
        range: true,
        min: 0,
        max: 300,
        values: [50, 200],
        slide: function (event, ui) {
            $rangeSliderResult.val("$" + ui.values[0] + " - $" + ui.values[1]);
        }
    });
    $rangeSliderResult.val("$" + $rangeSlider.slider("values", 0) + " - $" + $rangeSlider.slider("values", 1));

    /*==========  image zoomsl Plugin  ==========*/
    // [Zoom Effect on Hovering] Find it in shop-single-product.html
    jQuery(".zoomin").imagezoomsl();
});