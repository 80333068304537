/**
 * @since 1.0.0
 * @version 1.0.0
 */

import 'bootstrap'
// import './_ga'
import './template/plugins'
import './template/main'

jQuery(($) => {
  let vid = $('.page-title__video video')

  $('.vidbg-controller').on('click', function ($event) {
    playPause($(this))
  })

  $('.service-item').on('click', handleProductCardClick)

  function playPause(button) {
    if (vid.get(0).paused) {
      vid.get(0).play()
      button.find('span').html('Pause')
      button.find('i').removeClass().addClass('fa fa-pause')
    } else {
      vid.get(0).pause()
      button.find('span').html('Play')
      button.find('i').removeClass().addClass('fa fa-play')
    }
  }

  function handleProductCardClick() {
    const $link = $(this).find('.service__footer a')
    if ($link.length > 0) {
      $link[0].click()
    }
  }
})
